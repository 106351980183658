<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Edit Brand</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/brands/list"
              button-text="Update Brand"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form" id="brand_form">
              <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-8">
                  <div class="my-5">
                    <div class="form-group row">
                      <label class="col-3">Name</label>
                      <div class="col-9">
                        <input
                          v-model="brand.name"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter brand name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Description</label>
                      <div class="col-9">
                        <textarea
                          v-model="brand.description"
                          class="form-control form-control-solid"
                          rows="8"
                          placeholder="Enter brand description"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3 font-weight-bolder">Web</label>
                      <div class="col-9">
                        <input
                          v-model="brand.webUrl"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter brand web"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Brand Logo</label>
                      <div class="col-9">
                        <vue2Dropzone
                          class="dropzone dropzone-default dropzone-primary dz-clickable"
                          ref="myVueDropzone"
                          @vdropzone-file-added="filesAdded"
                          @vdropzone-complete="complete"
                          @vdropzone-success="success"
                          id="dropzone"
                          :options="dropzoneOptions"
                          :useCustomSlot="true"
                        >
                          <div class="dropzone-msg dz-message needsclick">
                            <h3 class="dropzone-msg-title">
                              Ovdje prenesite slike ili kliknite da biste je
                              prenijeli.
                            </h3>
                            <span class="dropzone-msg-desc"
                              >Prenesite do 1 slika</span
                            >
                          </div>
                        </vue2Dropzone>
                        <div v-if="brand.featuredImage.src" class="d-flex mb-8">
                          <div
                            class="symbol symbol-70 flex-shrink-0 mr-4 bg-light"
                          >
                            <div
                              class="symbol-label"
                              :style="{
                                'background-image':
                                  'url(' +
                                  appPath +
                                  '/media/cache/resolve/50x50/' +
                                  brand.featuredImage.src +
                                  ')'
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2"></div>
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'EditBrand',
  components: {
    CardToolbar,
    vue2Dropzone
  },
  data() {
    return {
      brand: {
        name: null,
        description: null,
        webUrl: null,
        featuredImage: {
          src: '',
          alt: ''
        }
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Brands', route: '/brands/list' },
      { title: 'Add New' }
    ])

    ApiService.get('/brands', this.$route.params.id).then(({ data }) => {
      this.brand.name = data.name
      this.brand.description = data.description
      this.brand.webUrl = data.webUrl
      this.brand.featuredImage.src = data.featuredImage.src
      this.brand.featuredImage.alt = data.featuredImage.alt
    })
  },
  methods: {
    onSubmit() {
      this.patchBrand(this.$route.params.id, this.brand).then(({ status }) => {
        if (status === 200) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Brand successful update in database'
          })

          setTimeout(() => {
            this.$router.push({ name: 'brands-list' })
          }, 500)
        }
      })
    },
    async patchBrand(id, entity) {
      try {
        return await ApiService.update('/brands', id, entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    filesAdded() {},
    success(_, response) {
      this.brand.featuredImage.src = '/uploads/' + response
    },
    complete() {}
  }
}
</script>
